import React, { useState, useEffect } from "react";
import SEO from "../components/Seo/seo"
import styled from 'styled-components';
import { theme } from '../utils/theme';
import Layout from "../components/Layout/Layout";
import GalleryComponent from "../components/Gallery/GalleryComponent";
import SimpleReactLightbox from "simple-react-lightbox";
import { StyledMainContainer, StyledTitle } from './head';
import { SRLWrapper } from "simple-react-lightbox";
import { lightboxGalleryOptions } from '../utils/lightboxGalleryOptions';


// ---- List Styles ----

const StyleGalleryContainer = styled(StyledMainContainer)`
${theme.flex.centeredColumn};

h1 {
padding-bottom: 20px;
}
`


const GallerySubpage = props => {

    const [active, setActive] = useState('false');

    useEffect(() => {

        if (window.matchMedia(`(min-width: 576px)`).matches) {
            setActive('true');
        }

    }, []);


    return (
        <Layout>
            <SEO title="Gallery" />

            <StyleGalleryContainer>
                <StyledTitle>{props.uri.replace(/[/]/g, "")}</StyledTitle>
            </StyleGalleryContainer>

            {active === 'true' ?
                <SimpleReactLightbox >
                    <SRLWrapper options={lightboxGalleryOptions}>
                        <GalleryComponent />
                    </SRLWrapper>
                </SimpleReactLightbox>
                :
                <GalleryComponent />}

        </Layout>
    )
}

export default GallerySubpage;