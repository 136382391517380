import React from "react";
import styled from 'styled-components';
import { theme } from '../../utils/theme';
import Layout from "../Layout/Layout";
import { Link } from "gatsby";
import { StyledLeftContainer, StyledButtonWrapper } from '../../pages/head';


// ---- Images ----

import HeadImage1 from '../imagesStock/Head/HeadImage1';
import HeadImage2 from '../imagesStock/Head/HeadImage2';
import HeadImage3 from '../imagesStock/Head/HeadImage3';
import HeadImage4 from '../imagesStock/Head/HeadImage4';

import CoreImage1 from '../imagesStock/Core/CoreImage1';
import CoreImage2 from '../imagesStock/Core/CoreImage2';
import CoreImage3 from '../imagesStock/Core/CoreImage3';
import CoreImage4 from '../imagesStock/Core/CoreImage4';

import FrameImage1 from '../imagesStock/Frame/FrameImage1';
import FrameImage2 from '../imagesStock/Frame/FrameImage2';
import FrameImage3 from '../imagesStock/Frame/FrameImage3';
import FrameImage4 from '../imagesStock/Frame/FrameImage4';

import ControllersImage1 from '../imagesStock/Controllers_Img/ControllersImage1';
import ControllersImage2 from '../imagesStock/Controllers_Img/ControllersImage2';
import ControllersImage3 from '../imagesStock/Controllers_Img/ControllersImage3';
import ControllersImage4 from '../imagesStock/Controllers_Img/ControllersImage4';
import ControllersImage5 from "../imagesStock/Controllers_Img/ControllersImage5";

import BaseImage1 from '../imagesStock/Base/BaseImage1';
import BaseImage2 from '../imagesStock/Base/BaseImage1';
import BaseImage3 from '../imagesStock/Base/BaseImage3';
import BaseImage4 from '../imagesStock/Base/BaseImage4';
import BaseImage5 from '../imagesStock/Base/BaseImage5';
import BaseImage6 from '../imagesStock/Base/BaseImage6';
import BaseImage7 from '../imagesStock/Base/BaseImage7';
import BaseImage8 from '../imagesStock/Base/BaseImage8';
import BaseImage9 from '../imagesStock/Base/BaseImage9';

import LampImage1 from '../imagesStock/Buy/LampImage1';
import LampImage2 from '../imagesStock/Buy/LampImage1';
import LampImage3 from '../imagesStock/Buy/LampImage3';
import LampImage4 from '../imagesStock/Buy/LampImage4';
import LampImage5 from '../imagesStock/Buy/LampImage5';
import LampImage6 from '../imagesStock/Buy/LampImage6';
import LampImage7 from '../imagesStock/Buy/LampImage7';
import LampImage8 from '../imagesStock/Buy/LampImage8';
import LampImage9 from '../imagesStock/Buy/LampImage9';


// ---- Styles ----

const StyledMainGalleryContainer = styled(StyledLeftContainer)`
padding: 5%;
display: block;
`
const StyledRowImagesContainer = styled.div`
${theme.flex.centered};
margin: 5% 0;

${theme.media.mobile} {
${theme.flex.centeredColumn};
margin: 150px 0;
}
`
const StyledSingleImageWrapper = styled.div`
flex: 1;
margin: 5px;

img {
    border-radius: 10px;
}

${theme.media.mobile} {
width: 90vw;
}
`
const StyledFooter = styled.div`
${theme.flex.centeredColumn};
position: static;
width: 100%;
margin-top: 100px;

p {
    ${theme.font.antic};
    color: #c8c7e2;
    font-size: .6rem;
    opacity: .3;
}
`;


const GalleryNoLightboxComponent = props => {

    return (
        <Layout>
            <StyledMainGalleryContainer>

                <StyledRowImagesContainer>
                    <StyledSingleImageWrapper>
                        <HeadImage1 />
                    </StyledSingleImageWrapper>

                    <StyledSingleImageWrapper>
                        <HeadImage2 />
                    </StyledSingleImageWrapper>

                    <StyledSingleImageWrapper>
                        <HeadImage3 />
                    </StyledSingleImageWrapper>

                    <StyledSingleImageWrapper>
                        <HeadImage4 />
                    </StyledSingleImageWrapper>
                </StyledRowImagesContainer>


                <StyledRowImagesContainer>
                    <StyledSingleImageWrapper>
                        <CoreImage1 />
                    </StyledSingleImageWrapper>

                    <StyledSingleImageWrapper>
                        <CoreImage2 />
                    </StyledSingleImageWrapper>

                    <StyledSingleImageWrapper>
                        <CoreImage3 />
                    </StyledSingleImageWrapper>

                    <StyledSingleImageWrapper>
                        <CoreImage4 />
                    </StyledSingleImageWrapper>
                </StyledRowImagesContainer>


                <StyledRowImagesContainer>
                    <StyledSingleImageWrapper>
                        <FrameImage1 />
                    </StyledSingleImageWrapper>

                    <StyledSingleImageWrapper>
                        <FrameImage2 />
                    </StyledSingleImageWrapper>

                    <StyledSingleImageWrapper>
                        <FrameImage3 />
                    </StyledSingleImageWrapper>

                    <StyledSingleImageWrapper>
                        <FrameImage4 />
                    </StyledSingleImageWrapper>
                </StyledRowImagesContainer>


                <StyledRowImagesContainer>
                    <StyledSingleImageWrapper>
                        <ControllersImage1 />
                    </StyledSingleImageWrapper>

                    <StyledSingleImageWrapper>
                        <ControllersImage2 />
                    </StyledSingleImageWrapper>

                    <StyledSingleImageWrapper >
                        <ControllersImage3 />
                    </StyledSingleImageWrapper>

                    <StyledSingleImageWrapper >
                        <ControllersImage4 />
                    </StyledSingleImageWrapper>

                    <StyledSingleImageWrapper >
                        <ControllersImage5 />
                    </StyledSingleImageWrapper>
                </StyledRowImagesContainer>


                <StyledRowImagesContainer>
                    <StyledSingleImageWrapper>
                        <BaseImage1 />
                    </StyledSingleImageWrapper>

                    <StyledSingleImageWrapper>
                        <BaseImage2 />
                    </StyledSingleImageWrapper>

                    <StyledSingleImageWrapper >
                        <BaseImage3 />
                    </StyledSingleImageWrapper>

                    <StyledSingleImageWrapper >
                        <BaseImage4 />
                    </StyledSingleImageWrapper>

                    <StyledSingleImageWrapper >
                        <BaseImage5 />
                    </StyledSingleImageWrapper>

                    <StyledSingleImageWrapper >
                        <BaseImage6 />
                    </StyledSingleImageWrapper>

                    <StyledSingleImageWrapper >
                        <BaseImage7 />
                    </StyledSingleImageWrapper>

                    <StyledSingleImageWrapper >
                        <BaseImage8 />
                    </StyledSingleImageWrapper>

                    <StyledSingleImageWrapper >
                        <BaseImage9 />
                    </StyledSingleImageWrapper>
                </StyledRowImagesContainer>


                <StyledRowImagesContainer>
                    <StyledSingleImageWrapper>
                        <LampImage1 />
                    </StyledSingleImageWrapper>

                    <StyledSingleImageWrapper>
                        <LampImage2 />
                    </StyledSingleImageWrapper>

                    <StyledSingleImageWrapper >
                        <LampImage3 />
                    </StyledSingleImageWrapper>

                    <StyledSingleImageWrapper >
                        <LampImage4 />
                    </StyledSingleImageWrapper>

                    <StyledSingleImageWrapper >
                        <LampImage5 />
                    </StyledSingleImageWrapper>

                    <StyledSingleImageWrapper >
                        <LampImage6 />
                    </StyledSingleImageWrapper>

                    <StyledSingleImageWrapper >
                        <LampImage7 />
                    </StyledSingleImageWrapper>

                    <StyledSingleImageWrapper >
                        <LampImage8 />
                    </StyledSingleImageWrapper>

                    <StyledSingleImageWrapper >
                        <LampImage9 />
                    </StyledSingleImageWrapper>
                </StyledRowImagesContainer>


                <StyledButtonWrapper>
                    <Link title="This is a link to home page" as="a" to="/" aria-label="home"><h1>Back</h1></Link>
                </StyledButtonWrapper>

                <StyledFooter>
                    <p>materiei © {new Date().getFullYear()}</p>
                </StyledFooter>

            </StyledMainGalleryContainer>

        </Layout>
    );
}

export default GalleryNoLightboxComponent;